/**
COLORS
 */

:root {
    --gp-primary-color: #bf3027;
    --eco-primary-color: #00a388;
}

.bg-gp-primary {
    background: var(--gp-primary-color);
}

.bg-eco-primary {
    background: var(--eco-primary-color);
}

.text-gp-primary {
    color: var(--gp-primary-color);
}

.text-hidden {
    opacity: 0 !important;
}

.list-group-item-link:hover {
    background: #cfe2ff;
}

.bg-black-20 {
    background: rgb(0 0 0 / 20%);
}

/**
POSITIONS
 */

.justify-self-center {
    justify-self: center;
}

.input-qte {
    width: 5em;
}

.pagination {
    justify-content: center;
}

.grid-title {
    grid-column: 1 / -1;
}

.grid-fill {
    grid-column: 1 / -1;
}

.d-flex h1 {
    margin: 0;
}

.action-buttons {
    grid-column: span 2;
}


/**
BUTTONS
 */

.btn-outline-success.disabled {
    border: none !important;
    opacity: initial !important;
}

.content-half > div,
.content-half > .btn {
    flex: 1 0 50%;
}

/**
STRUCTURE
 */

html,
body {
    height: 100vh;
}

body {
    display: grid;
    grid-template-columns: 100vw;
    grid-template-rows: repeat(4, auto) 1fr auto;
}

.span-2 {
    grid-column: span 2;
}

/**
FOOTER
 */

.footer-inner {
    grid-template-columns: 1fr;
}

footer h2 {
    font-size: 1rem;
}

footer p,
footer a {
    font-size: 0.8rem;
}

@media (min-width: 768px) {
    .footer-inner {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto 1fr;
        grid-template-areas:
            "about about"
            "contact network"
            "contact newsletter";
        gap: 1em;
    }

    .about-us {
        grid-area: about;
    }

    .contact-info {
        grid-area: contact;
    }

    .social-network {
        grid-area: network;
    }

    .newsletter {
        grid-area: newsletter;
    }
}

@media (min-width: 992px) {

    .footer-inner {
        grid-template-columns: 2fr 1fr 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas:
            "about contact network"
            "about contact newsletter";
        gap: 1em 1.5em;
    }
}

@media (min-width: 1200px) {
    footer h2 {
        font-size: 1.5rem;
    }

    footer p,
    footer a .text {
        font-size: 0.9rem;
    }
}


/**
CARD PAPIER
 */

.card-papier-wrapper {
    display: grid;
    gap: 1.2em;
}

.card-papier h5 {
    font-size: 1.10rem;
}

.card-papier .card-text {
    grid-template-columns: auto 1fr;
}

@media (min-width: 992px) {

    .card-papier-wrapper {
        grid-template-columns: repeat(auto-fill, minmax(22em, 1fr));
    }
}


/**
CARD BUREAUTIQUE
 */

.card-bureautique-wrapper {
    display: grid;
    gap: 1.2em;
}

.card-bureautique img {
    height: 8em;
    object-fit: contain;
}

.card-bureautique h2 {
    font-size: 1.10rem;
}

.card-bureautique .card-text {
    grid-template-columns: auto 1fr;
}

@media (min-width: 992px) {

    .card-bureautique-wrapper {
        grid-template-columns: repeat(auto-fill, minmax(22em, 1fr));
    }
}


/**
CARD ADRESSE
 */

.adresse-entreprise .meta {
    flex: 0;
}

/**
NAVBAR
 */
.navbar-brand .small-logo {
    width: 8.4em;
}

.navbar-search {
    flex-basis: 25em; /*35em;*/
}

#main-menu .navbar {
    overflow-x: scroll;
}

#main-menu .nav-link {
    white-space: nowrap;
}

.input-qte {
    text-align: center;
}

.input-qte::placeholder {
    color: var(--bs-gray-500);
}


/**
    PROMOTIONS
 */

#promotions article {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas:
            "reference-wrapper prix-regular-wrapper prix-promo-wrapper"
            "description-wrapper description-wrapper description-wrapper"
            "action-buttons action-buttons action-buttons";
}

#promotions .reference-wrapper {
    grid-area: reference-wrapper;
}

#promotions .prix-regular-wrapper {
    grid-area: prix-regular-wrapper;
}

#promotions .prix-promo-wrapper {
    grid-area: prix-promo-wrapper;
}

#promotions .description-wrapper {
    grid-area: description-wrapper;
}

#promotions .action-buttons {
    grid-area: action-buttons;
    justify-self: center;
}

#promotions article {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}


@media (min-width: 992px) {

    #promotions .items-header,
    #promotions article {
        grid-template-columns: 7em 1fr 7em 7em 19em;
    }

    #promotions article {
        grid-template-areas: "reference-wrapper description-wrapper prix-regular-wrapper prix-promo-wrapper action-buttons";
        border-top: none;
    }

    #promotions .action-buttons {
        width: -webkit-fill-available;
        justify-self: start;
    }

    #promotions form {
        width: -webkit-fill-available;
    }
}


/**
AFFICHAGE DES PAPIERS
 */

.papier-inner {
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.caracteristique-wrapper {
    border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.caracteristique-wrapper:nth-child(even) {
    border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}


@media (min-width: 992px) {

    .papier-inner {
        border-top: none;
    }

    .caracteristique-wrapper {
        border: none;
    }

    .caracteristique-wrapper:nth-child(even) {
        border-left: none;
    }
}


/**
AFFHICHAGE DES ARTICLES BUREAUTIQUE
 */

.bureautique-item.card .item-first-photo {
    height: 10em;
    object-fit: contain;
}

.bureautique-item.card .card-text {
    grid-template-columns: auto 1fr;
    gap: 0 1em;
}

.bureautique-item.card .card-text .label {
    font-size: smaller;
}

.bureautique-item.card .card-meta {
    grid-template-columns: auto 1fr;
    gap: 0.3em 1em;
    font-size: smaller;
}

.bureautique-item .action-buttons>a {
    flex-basis: 100%;
}


@media (min-width: 992px) {

    .bureautique-item.card .item-first-photo {
        height: 13em;
        max-width: 100%;
        object-fit: contain;
    }

}


/**
AFFICHAGE D'UN SEUL ARTICLE BUREAUTIQUE
 */

#article-synthese {
    grid-template-areas: "article-title" "article-carousel" "synthese-group" "article-dispo" "input-cart";
    align-items: start;
}

.article-title {
    grid-area: article-title;
}

.synthese-group {
    grid-area: synthese-group
}

#item-carousel {
    grid-area: article-carousel;
}

.article-codes {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    grid-template-columns: auto 1fr;
    gap: 0.2em 1em;
}

.dispo {
    grid-area: article-dispo;
}

.input-cart {
    grid-area: input-cart;
}

.article-specifications-content {
    grid-template-columns: 1fr 1fr;
    gap: 0.4em 0.5em;
}

.article-pdfs a {
    width: fit-content;
}

.article-sibling img {
    height: 15em;
    object-fit: contain;
}

@media (min-width: 768px) {

    #article-synthese {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
                "article-carousel article-title"
                "article-carousel synthese-group"
                "article-carousel article-dispo"
                "article-carousel input-cart";
    }

    .carousel-item img {
        height: 22rem;
        object-fit: contain;
    }
}

@media (min-width: 992px) {

    .carousel-item img {
        height: 25rem;
         object-fit: contain;
    }

    .article-details {
        grid-template-columns: 20rem 1fr;
        grid-template-areas:
                ". details-title"
                ". article-pdfs"
                "description-title article-description-content"
                "specifications-title article-specifications-content";
        gap: 1.3em 0;
    }

    .article-details h2 {
        grid-area: details-title;
    }

    .article-pdfs {
        grid-area: article-pdfs;
    }

    .description-title {
        grid-area: description-title;
    }

    .article-description-content {
        grid-area: article-description-content;
    }

    .specifications-title {
        grid-area: specifications-title;
    }

    .article-specifications-content {
        grid-area: article-specifications-content;
        grid-template-columns: auto 1fr;
        gap: 0 1em;
    }

}



/**
ACCESSOIRES
 */

.accessoire-inner {
    /*border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);*/
    gap: 0.2em;
}

.accessoire-inner .caracteristique-wrapper {
    flex: 0 1 45%;
    border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

@media (min-width: 768px) {
    .accessoire-category .accessoire-image {
        width: 16rem;
    }
}

@media (min-width: 992px) {
    .accessoire-category .accessoire-image {
        width: 19rem;
    }

    .accessoire-inner {
        border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
    }

    .accessoire-inner .caracteristique-wrapper {
        flex: unset;
        border: none;
    }

    #accessoires form {
        width: -webkit-fill-available;
    }
}


/**
RECHERCHES
 */

#count-search-papiers {
    background: var(--gp-primary-color);
}

.result-search {
    grid-template-columns: auto 1fr;
    gap: 0 1em;
}


/**
LOGIN
 */

.login-card .card-img {
    height: 30em;
    object-fit: cover;
}

.inactives-images .login-card .card-img {
    height: 14.6em;
}

.login-card .update-button {
    position: absolute;
    right: 1rem;
}

.current-login-image img {
    height: 15rem;
}


/**
ADRESSES DES ENTREPRISES
 */

.card.adresse-entreprise .card-text {
    margin: 0;
}

.card.adresse-entreprise .card-footer a {
    font-size: small;
}


/**
CART
 */

.cart-item {
    grid-template-columns: 1fr;
    grid-template-areas:
            "description"
            "reference"
            "quantite"
            "prices"
            "actions"
    ;
}

.cart-item.has-image {
    grid-template-columns: 4fr 6fr;
    grid-template-areas:
            "image description"
            "image reference"
            "image quantite"
            "prices prices"
            "actions actions"
;
}

.cart-item-image {
    grid-area: image;
}

#cart-header .description,
.cart-item-description {
    grid-area: description;
}

#cart-header .reference,
.cart-item-reference {
    grid-area: reference;
}

#cart-header .prices,
.cart-item-prices {
    grid-area: prices;
}

#cart-header .quantite,
.cart-item-quantite {
    grid-area: quantite;
}

#cart-header .actions,
.cart-item-actions {
    grid-area: actions;
}

.cart-item-image img {
    width: 100%;
    max-height: 7rem;
    object-fit: contain;
}

@media (min-width: 768px) {

    .cart-item {
        grid-template-columns: 1fr auto;
        grid-template-areas:
            "description reference"
            "prices actions"
    ;
    }

    .cart-item.has-image {
        grid-template-columns: 9em 1fr auto;
        grid-template-areas:
            "image description description"
            "image reference reference"
            "image prices actions"
    ;
    }

    .cart-item-description {
        flex: auto;
    }

    .display-order .cart-item-prices,
    .display-order .cart-item-actions {
        flex: 45%;
    }
}

@media (min-width: 992px) {

    .commande {
        border: 1px solid lightgrey;
        padding: 0.8em;
        border-radius: 6px;
    }

    #cart-header,
    .cart-item {
        grid-template-columns: 9em 31em 1fr 2fr 1fr;
        grid-template-areas: "description description reference prices actions";
    }

    .cart-item.has-image {
        grid-template-columns: 9em 31em 1fr 2fr 1fr;
        grid-template-areas: "image description reference prices actions";
    }
    
    .display-order .cart-item-image {
        flex: 0 0 5em;
    }

    /*.cart-item-description {*/
    /*    flex: 0 0 33em;*/
    /*}*/

    .display-order .cart-item-reference,
    .display-order #cart-header .reference {
        flex: 0 0 13em;
    }

    .display-order .cart-item-quantite,
    .display-order #cart-header .quantite{
        flex: 0 0 4.5em;
    }

    .display-order .cart-item-prices,
    .display-order #cart-header .prices {
        flex: 0 0 22em;
        gap: 0 0.5em;
    }

    .display-order .cart-item-actions,
    .display-order #cart-header .actions{
        flex: 0 0 15.5em;
    }

}


/**
CART CONVERT POINTS
 */

#convert-points-content .value {
    flex: 0 0 5em;
}

#points-to-use-form .alert.alert-danger {
    margin-bottom: 0;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}



@media (min-width: 768px) {

    #points-to-use-inner {
        grid-template-columns: 1fr auto;
        grid-template-areas:
            "label widgets"
            "message message"
            "error error";
    }

    #points-to-use-group .label {
        grid-area: label;
    }

    #points-to-use-group .widgets {
        grid-area: widgets;
    }

    #points-to-use-group .message {
        grid-area: message;
    }

    #points-to-use-group .form-error {
        grid-area: error;
        place-self: end;
    }
}

@media (min-width: 992px) {

    #convert-points-content {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            "current-balance current-total-ttc"
            "points-to-use amount-converted"
            "points-acquired new-total-ttc"
            "new-balance ."
            "convert-points-action convert-points-action";
    }

    #current-balance-group {
        grid-area: current-balance;
    }

    #current-total-ttc-group {
        grid-area: current-total-ttc;
    }

    #points-to-use-group {
        grid-area: points-to-use;
    }

    #amount-converted-group {
        grid-area: amount-converted;
    }

    #new-total-ttc-group {
        grid-area: new-total-ttc;
    }

    #points-acquired-group {
        grid-area: points-acquired;
    }

    #new-balance-group {
        grid-area: new-balance;
    }

    .convert-points-action-buttons {
        grid-area: convert-points-action;
    }

    #points-to-use-group .label {
        flex: 1 0 auto;
    }

    #points-to-use-group .form-control {
        font-weight: 0.9em;
        line-height: 1;
    }

    #points-to-use-inner {
        grid-template-columns: auto auto;
    }

    #points-to-use-group .form-error {
        place-self: center;
    }

}

.commande ~ .commande {
    margin-top: 1.2em;
}

.commande .titre-commande {
    font-size: 1.35rem;
    font-variant: all-small-caps;
}

.list-group-item .text,
.list-group-item .text:active,
.list-group-item .text:hover,
.list-group-item .text:focus,
.list-group-item .text:visited {
    /*background: red;*/
}

.handle {
    cursor: move;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

/**
UPDATE CATEGORY
 */
.update-category-image {
    height: 15em;
    width: auto;
}

/**
UPDATE SETTINGS
 */

/*.update-settings {*/
/*    grid-template-columns: 1fr 23em 6em repeat(2, auto);*/
/*    gap: 2em;*/
/*    align-items: center;*/
/*}*/

form[name="setting"] {
    height: 100%;
}
.setting-card-body {
    height: 100%;
    display: grid;
    grid-template-rows: repeat(4, auto) 1fr;
    align-content: start;
}

.setting-actif-submit {
    align-self: end;
}

/**
ADMIN PAPIERS
 */

/*form[name="papier"] .form-label {*/
/*    margin-bottom: 0.2rem;*/
/*}*/

form[name="papier"] input[type="text"] {
    min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width)* 2));
    padding: 0.25rem 0.5rem;
    font-size: 0.845rem; /* la valeur initiale est 0.875rem */
    border-radius: var(--bs-border-radius-sm);
}

form[name="papier"] select {
    min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width)* 2));
    padding: 0.25rem 1.7rem 0.25rem 0.5rem;
    font-size: 0.845rem; /* la valeur initiale est 0.875rem */
    border-radius: var(--bs-border-radius-sm);
}

form[name="papier"] .form-check {
    margin: 0;
}

form[name="papier"] .form-check-label {
    font-size: 0.845rem;
}

#siblings-update-papier .list-group-item {
    grid-template-columns: repeat(3, 1fr);
}

#siblings-update-papier .prix-client {
    justify-self: end;
}

#siblings-update-papier .list-group-item div:last-child {
    grid-column: span 3;
}

.card-slide {
    position: relative;
}

.card-slide-image {
    height: 109px;
    object-fit: cover;
}

.card-slide .update-button,
.card-slide .reactivate-button {
}

.slide-current-image {
    width: 100%;
    height: 160px;
    object-fit: cover;
}

@media (min-width: 768px) {
    .card-slide-image {
        height: 174px;
    }
}

@media (min-width: 992px) {
    .card-slide-image {
        height: 189px;
    }

    #sortable-slides .card-slide-image {
        height: 5.5rem;
    }
}

form[name="maintenance_banner"] .alert {
    margin-bottom: 0;
    padding: 0.6rem 2.6rem;
}

/**
SYNTHÈSE ENTREPRISE
 */
.grid-adresses {
    grid-template-areas:
        "label-facturation"
        "adresse-facturation"
        "label-livraison"
        "adresse-livraison";
}

.label-facturation {
    grid-area: label-facturation;
}

.label-livraison {
    grid-area: label-livraison;
}

.adresse-facturation {
    grid-area: adresse-facturation;
}

.adresse-livraison {
    grid-area: adresse-livraison;
}

@media (min-width: 768px) {
    .grid-adresses {
        grid-template-areas:
            "label-facturation label-livraison"
            "adresse-facturation adresse-livraison";
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 992px) {
    .grid-adresses {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto 1fr;
    }
}

#secondary-carousel a {
    height: 10em;
    text-align: center;
}

#secondary-carousel a img {
    height: 100%;
    object-fit: contain;
}